<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <div class="col-12">
            <div class="card">
                <Toast />
                <Panel header="Sucursal">
                    <BasicFormToolbar @new="openNew" @refresh="refresh" @save="save" :actions="['new', 'refresh','save']" />
                    <div class="grid formgrid p-fluid">
                        <div class="col-12">
                            <TabView ref="tab">
                                <TabPanel header="Datos Generales">
                                    <div class="p-fluid formgrid grid">
                                        <FormDropdown :wrapperClass="'field col-12'" label="Empresa" :disabled="entity.id" v-model="entity.id_company" :valid="validate.validations.id_company" :options="companies" :optionLabel="'name'" :optionValue="'id'"   />
                                        <FormInputText wrapperClass="field col-4" label="Razón Social / Nombre SAT" tooltip="Favor de agregar el mismo nombre que aparece en la lista del SAT" v-model="entity.name" :valid="validate.validations.name" />
                                        <FormInputText wrapperClass="field col-4" label="Nombre Comercial" v-model="entity.key_name" :valid="validate.validations.key_name" />
                                        <FormInputText wrapperClass="field col-4" label="RFC" v-model="entity.tax_number" :valid="validate.validations.tax_number" />
                                        <FormDropdownComplex :placeholder="'Seleccione un regimen'" :labelInOption="'c_RegimenFiscal - Descripcion'" :labelInValue="'c_RegimenFiscal - Descripcion'" :wrapperClass="'field col-4'" label="Regimen Fiscal" v-model="entity.tax_regime" :options="c_RegimenFiscal" optionLabel="c_RegimenFiscal" optionValue="c_RegimenFiscal" />
                                        <FormInputText wrapperClass="field col-8" label="Direccion" v-model="entity.address" :valid="validate.validations.address" />
                                        <FormInputText wrapperClass="field col-4" label="Colonia" v-model="entity.suburb" :valid="validate.validations.suburb" />
                                        <FormInputText wrapperClass="field col-4" label="Ciudad" v-model="entity.city" :valid="validate.validations.city" />
                                        <FormInputText wrapperClass="field col-4" label="Estado" v-model="entity.state" :valid="validate.validations.state" />
                                        <FormInputText wrapperClass="field col-4" label="CP" v-model="entity.zip_code" :valid="validate.validations.zip_code" />
                                        <FormInputText wrapperClass="field col-4" label="Email" v-model="entity.email" :valid="validate.validations.email" />
                                        <FormInputText wrapperClass="field col-3" autocomplete="off" label="Telefono" v-model="entity.phone" :valid="validate.validations.phone" />
                                        <FormInputSwitch :wrapperClass="'field col-1'" :label="'Activo'" v-model="entity.is_active" />
                                    </div>
                                </TabPanel>
                                <TabPanel header="Certificado">
                                    <div class="p-fluid formgrid grid">
                                        <div class="field col-12">
                                            <label for="">Archivo .CER</label>
                                            <FileUpload @before-send="onBeforeSend" @error="uploadError" name="demo[]" :url="uploadCer" @upload="onUpload" :multiple="false" accept=".cer" :maxFileSize="1000000">
                                                <template #empty>
                                                    <p>Arrastra para cargar el archivo.</p>
                                                </template>
                                            </FileUpload>
                                        </div>
                                        <FormInputText wrapperClass="field col-4" label="Numero Certificado" v-model="entity.certificate.cer_number" :readonly="true"/>
                                        <FormInputText wrapperClass="field col-4" label="RFC" v-model="entity.certificate.tax_number" :readonly="true" />
                                        <FormInputText wrapperClass="field col-4" label="Empresa" v-model="entity.certificate.cer_name" :readonly="true"/>
                                        <FormCalendar wrapperClass="field col-4" label="Fecha Inicio" v-model="entity.certificate.initial_date" :disabled="true"  />
                                        <FormCalendar wrapperClass="field col-4" label="Fecha Vencimiento" v-model="entity.certificate.final_date" :disabled="true"  />
                                        <FormInputText wrapperClass="field col-4" label="Nombre Archivo" v-model="entity.certificate.cer_filename" :readonly="true"/>
                                        <div class="field col-12">
                                            <label for="">Certificado</label>
                                            <Textarea v-model="entity.certificate.cer" :disabled="true" rows="5" cols="30" />
                                        </div>
                                        <div class="field col-12">
                                            <label for="">Archivo .KEY</label>
                                            <FileUpload @before-send="onBeforeSend" @error="uploadError" :url="urlKey" @upload="onUploadKey" :multiple="false" accept=".key" :maxFileSize="1000000">
                                                <template #empty>
                                                    <p>Arrastra para cargar el archivo.</p>
                                                </template>
                                            </FileUpload>
                                        </div>
                                        <FormInputText wrapperClass="field col-4" autocomplete="new-password" label="Password" v-model="entity.certificate.cer_key_password" :type="'password'"/>
                                        <FormInputText wrapperClass="field col-8" label="Nombre Archivo" v-model="entity.certificate.cer_key_file" :readonly="true"/>
                                    </div>
                                </TabPanel>
                                <TabPanel header="Configuracion">
                                    <div class="p-fluid formgrid grid">
                                        <Divider align="left">
                                            <div class="inline-flex align-items-center">
                                                <i class="pi pi-user mr-2"></i>
                                                <b>Facturacion</b>
                                            </div>
                                        </Divider>
                                        <FormInputSwitch :wrapperClass="'field col-2'" :label="'Facturación Real'" v-model="entity.is_invoice_prod" />
                                        <FormInputText wrapperClass="field col-3" label="Usuario" v-model="entity.invoice_user" />
                                        <FormInputText wrapperClass="field col-3" label="Password" :type="'password'" autocomplete="new-password" v-model="entity.invoice_password" />

                                        <Divider align="left">
                                            <div class="inline-flex align-items-center">
                                                <i class="pi pi-user mr-2"></i>
                                                <b>API</b>
                                            </div>
                                        </Divider>
                                        <FormInputSwitch :wrapperClass="'field col-2'" :label="'Usuario API Externo'" v-model="entity.is_external_api_user" />
                                        <FormInputText wrapperClass="field col-3" label="API Key" v-model="entity.api_key" />
                                    </div>  
                                    
                                </TabPanel>
                                <TabPanel v-if="entity.id" header="Logo">
                                    <div class="p-fluid formgrid grid">
                                        <div class="col-6">
                                            <Image style="align: center" :src="logoURL" alt="Logo Sucursal" width="350" preview />
                                        </div>
                                        <div class="field col-6">
                                            <FileUpload @before-send="onBeforeSend" name="demo[]" :url="uploadURL" @upload="onUploadLogo" :multiple="false" accept="image/*" :maxFileSize="1000000">
                                                <template #empty>
                                                    <p>Arrastra la imagen aqui</p>
                                                </template>
                                            </FileUpload>
                                        </div>
                                    </div>
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                    <BasicDatatable :rowedit="true" :rowaction="true" :rowdelete="true" :headers="headers" :rows="entities" @edited="edit" @deleted="confirmDelete" />
                    <ConfirmDeleteDialog @closed="deleteDialog = false" v-model="deleteDialog" @deleted="deleteEntity" />
                </Panel>
            </div>
        </div>
    </div>
</template>

<script>
import { HeaderGrid, Rule, validate, fillObject, Toast, ErrorToast, satCatalogo } from '../../../utilities/General';
import Loader from '../../../components/general/Loader.vue';
import Session from '../../../mixins/sessionMixin';
import { Branch } from '../../../models/general/Branch';
import { Company } from '../../../models/general/Company';
import { BranchCertificate } from '../../../models/general/BranchCertificate';
import axios from 'axios';
import ConfirmDeleteDialog from '../../../components/general/DeleteDialog.vue';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import FormInputText from '../../../components/general/FormInputText.vue';
import FormInputSwitch from '../../../components/general/FormInputSwitch.vue';
import FormCalendar from '../../../components/general/FormCalendar.vue';
import FormDropdownComplex from '../../../components/general/FormDropdownComplex.vue';
import FormDropdown from '../../../components/general/FormDropdown.vue';


export default {
    mixins: [Session],
    data() {
        return {
            c_RegimenFiscal: [],
            entity: null,
            certificate: new BranchCertificate(this.session),
            entities: [],
            companies: [],
            uploadCer: '',
            //* URL para subir el importador
            urlKey: null,
            deleteDialog: false,
            filters: {},
            //* Aqui agregamos las reglas de validacion, si solo agregamos el nombre sera
            //* 'required' de tipo 'text'
            rules: [
                new Rule({ name: 'id_company' }),
                new Rule({ name: 'name' }),
                new Rule({ name: 'key_name' }),
                new Rule({ name: 'address' }),
                new Rule({ name: 'suburb' }),
                new Rule({ name: 'city' }),
                new Rule({ name: 'state' }),
                new Rule({ name: 'tax_number', type: 'rfc' }),
                new Rule({ name: 'zip_code' }),
                new Rule({ name: 'phone' }),
                new Rule({ name: 'email', type: 'email' }),
            ],
            //* Aqui agregamos el estatus de la validacion, inicializar en las props 'null'
            //* y el valid general en false (por que no esta validado aun)
            validate: {
                valid: false,
                validations: {
                    name: null,
                    key_name: null,
                },
            },
            //* Headers donde pondremos el nombre y el valor del datatable
            headers: [
                new HeaderGrid("Empresa", "company_name", { type:"upper", formula: "search", expression: "find", data: [], data_key: "id", data_search_key: "id_company", data_value: "name"}),
                new HeaderGrid('Nombre', 'name'), 
                new HeaderGrid('Clave', 'key_name'), 
                new HeaderGrid('Direccion', 'address'), 
                new HeaderGrid('RFC', 'tax_number'), 
                new HeaderGrid('Email', 'email')
            ],
            loading: false,
        };
    },
    computed: {
        regimenFiscalReceptor() {
            return this.entity.tax_number ? 
                    this.entity.tax_number.length > 12 
                    ? this.c_RegimenFiscal.filter(x => x.Fisica == 'Sí')
                    : this.c_RegimenFiscal.filter(x => x.Moral == 'Sí')
                : this.c_RegimenFiscal.filter(x => x.Fisica == 'Sí');
        },
        uploadURL() {
            return this.$config.api_route + 'General/Branch/logo/' + this.entity.id;
            
        },
        logoURL() {
            if (!this.$config.api_route) {
                this.$toast.add(new ErrorToast('No existe la configuracion para api_route', { severity: "warn", summary: "Advertencia"}));
                return null;
            } else {
                return this.$config.api_route + 'General/Branch/image/' + "Logo_Sucursal_" +  this.entity.id;
            }
        },
    },
    components: { FormDropdownComplex,FormDropdown, FormCalendar, FormInputSwitch, FormInputText, Loader, BasicFormToolbar, BasicDatatable, ConfirmDeleteDialog },
    created() {
        this.entity = new Branch(this.session);
        this.uploadCer = this.$config.api_route + 'General/Branch/certificate';
        this.urlKey = this.$config.api_route + 'General/Branch/key';
    },
    async mounted() {
        this.c_RegimenFiscal = await satCatalogo(2);
        await this.refresh();
    },
    methods: {
        onBeforeSend(request) {
            request.xhr.setRequestHeader('API_KEY', process.env.VUE_APP_API_KEY);
            request.xhr.setRequestHeader('company', this.$storage.getStorageSync("company"));
            request.xhr.setRequestHeader('branch', this.$storage.getStorageSync("branch"));
            request.xhr.setRequestHeader('user', this.$storage.getStorageSync("usuario"));
            return request;
        },
        async onUploadKey(payload) {
            try {
                this.loading = true;
                let file = payload.files[0];
                let filename = file.name.split('.')[0];
                this.entity.certificate.cer_key_file = filename;
                this.entity.certificate.cer_key_password = '';
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async onUpload(payload) {
            try {
                this.loading = true;
                let file = payload.files[0];
                let filename = file.name.split('.')[0];
                let cer = (await axios.get('General/Branch/certificate/' + filename)).data;
                this.entity.certificate = new BranchCertificate(this.session);
                this.entity.certificate.cer_number = cer.serialNumber;
                this.entity.certificate.tax_number = cer.rfc;
                this.entity.certificate.cer_name = cer.company;
                this.entity.certificate.cer_filename = filename;
                this.entity.certificate.cer = cer.data;
                this.entity.certificate.initial_date = cer.effectiveDate ? new Date(cer.effectiveDate) : null;
                this.entity.certificate.final_date = cer.expirationDate ? new Date(cer.expirationDate) : null;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        openNew() {
            this.entity = new Branch(this.session);
        },
        async save() {
            try {
                //* Validacion de form
                this.loading = true;
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) {
                    throw 'Favor de validar los campos';
                }
                //* Si el id es != 0 entonces actualizamos, si no, guardamos
                if (this.entity.id && this.entity.id > 0) {
                    //* Actualizamos
                    let entity = await this.entity.update();
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Actualizar',
                        detail: 'Informacion actualizada con exito',
                        life: 3000,
                    });
                    //* Modificamos el listado pah
                    let index = this.entities.findIndex((x) => x.id == this.entity.id);
                    this.entities[index] = entity;
                } else {
                    //* Creamos uno nuevo
                    let entity = await this.entity.save();
                    this.entities.push(entity);
                    this.$toast.add(
                        new Toast({
                            summary: 'Creacion',
                            detail: 'Informacion guardada con exito',
                        })
                    );
                }
                this.openNew();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        edit(entity) {
            this.entity = fillObject(this.entity, entity);
            this.entity.certificate = this.entity.certificate ? this.entity.certificate : new BranchCertificate(this.session);
        },
        onUploadLogo(ev) {
            console.log(ev);
            this.$toast.add({
                severity: 'success',
                summary: 'Logo',
                detail: 'Logo actualizado con exito',
                life: 3000,
            });
        },
        confirmDelete(entity) {
            this.entity = fillObject(this.entity, entity);
            this.deleteDialog = true;
        },
        async deleteEntity() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.entity.delete();
                //* Eliminamos de la vista
                this.entities = this.entities.filter((val) => val.id !== this.entity.id);
                this.deleteDialog = false;
                //* Limpiamos la entidad
                this.entity = new Branch(this.session);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        uploadError() {
            this.$toast.add(new ErrorToast('Error al subir archivo, intente de nuevo'));
        },
        async refresh() {
            this.loading = true;
            this.entity.certificate = new BranchCertificate(this.session);
            try {
                this.entities = await this.entity.data({
                    id_company: this.entity.id_company,
                });
                this.companies = await new Company().all();
                this.headers[0].data = this.companies;

            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
}
</style>
